@import "src/styles/mixins";

cx-carousel {
  --jaga-color-carousel-button: #bebebe;

  .indicators {
    button {
      .cx-icon {
        border: none;
        color: var(--jaga-color-carousel-button);
      }
    }
  }

  h3 {
    @include header-2;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  jaga-add-to-cart {
    button {
      color: var(--cx-color-primary);
    }
  }
}
