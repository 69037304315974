@import "src/styles/variables";

$header-item-side-margin: 0.5rem;

header {
  background-color: var(--cx-color-inverse);
  color: var(--cx-color-dark);

  @include media-breakpoint-up(lg) {
    background: var(--cx-color-inverse);
  }

  &.is-expanded {
    .header {
      .SiteLogo {
        z-index: 4;
      }

      .PreHeader {
        .cx-hamburger {
          .hamburger-inner {
            background-color: var(--cx-color-primary);

            &::before,
            &::after {
              background-color: var(--cx-color-primary);
              top: 0;
            }
          }
        }
      }
    }
  }

  .header {
    padding: 1rem 0;
    position: relative;

    @include media-breakpoint-up(sm) {
      padding-bottom: 0.25rem;
    }

    & > * {
      align-self: flex-start;
    }

    .SiteLogo {
      margin: 0 0 0 1rem;

      @include media-breakpoint-up(md) {
        margin: 0 $header-item-side-margin;
      }
    }

    .SearchBox {
      margin: 0 0 0 auto;

      @include media-breakpoint-up(lg) {
        margin: 0 auto;
      }
    }

    .SiteLinks {
      color: var(--cx-color-primary);
      flex: 0;
      font-size: 0.9em;
      font-weight: var(--cx-font-weight-normal);
      margin: 0 $header-item-side-margin;
    }

    .SiteLogin {
      padding: 0;
      margin: 0 $header-item-side-margin;

      @include media-breakpoint-down(md) {
        background: none;
        margin: 0 0 0 $header-item-side-margin;
      }
    }

    .MiniCart {
      margin: 0 $header-item-side-margin;
    }

    .PreHeader {
      margin-right: 0.5rem;

      .cx-hamburger {
        display: block;
        width: 35px;
        height: 35px;
        padding: 9px 8px;
        border: 1px solid var(--cx-color-primary);
        border-radius: 100%;

        .hamburger-inner {
          &,
          &::before,
          &::after {
            background-color: var(--cx-color-primary);
            width: 17px;
            height: 2px;
          }

          &::before {
            top: -5px;
          }

          &::after {
            top: 5px;
          }
        }
      }
    }
  }

  .jagaNavigation {
    background: var(--cx-color-light);

    .NavigationBar {
      position: relative;
      min-height: 40px;
    }
  }

  .navigation {
    padding-top: 1rem;
  }

  @include media-breakpoint-down(md) {
    .NavigationBar {
      background-color: var(--cx-color-light);
    }

    &.is-expanded {
      &::after {
        background-color: var(--cx-color-light);
      }
    }
  }
}
