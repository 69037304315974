@import 'src/styles/variables';

.ContentPage3Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-content;
  margin: 0 auto;

  cx-page-slot {
    flex-basis: 100%;

    &.Section3A {
      jaga-map {
        iframe {
          height: 500px;
          width: 100%;
        }
      }
    }

    &.Section3B {
      jaga-paragraph {
        padding: 0 2rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    cx-page-slot {
      &.Section3A, &.Section3B,
      &.Section6A, &.Section6B {
        flex-basis: $jaga-flex-basis-half;
      }

      &.Section4A, &.Section4B, &.Section4C, &.Section4D,
      &.Section7A, &.Section7B, &.Section7C, &.Section7D {
        flex-basis: $jaga-flex-basis-quarter;
      }
    }
  }
}
