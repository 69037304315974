@import "../../../../variables";
@import "../../../../mixins";

cx-checkout-progress-mobile-top,
cx-checkout-progress-mobile-bottom {
  .cx-media {
    .cx-list-media {
      font-size: 1.5em;
      line-height: 1.5em;
      padding: 0.5rem 1rem;
    }
  }
}

cx-checkout-progress-mobile-bottom {
  margin-top: 1rem;
}

jaga-checkout-progress {
  @extend %cx-checkout-progress;

  .cx-nav {
    padding: 0.5rem 0.75rem;
    background: var(--cx-color-light);

    .cx-item {
      align-items: center;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0;

      &:not(:last-child)::after {
        content: $jaga-fa-angle-right;
        color: var(--cx-color-secondary);
        padding: 0 0.55rem;

        @include jaga-fa;
      }

      .cx-link {
        white-space: nowrap;
        font-size: $jaga-base-font-size;

        &::after {
          content: "";
          padding: 0;
        }
      }
    }
  }

  .cx-link {
    font-size: 0.9em;
    text-transform: uppercase;

    &.disabled {
      color: var(--cx-color-secondary);

      &::after {
        color: var(--cx-color-secondary);
      }
    }
  }
}
