@import '../../../variables';
@import '../../../mixins';

.top-container {
  background: var(--cx-color-light);

  cx-page-layout.top {
    max-width: 1140px;
    margin: 0 auto;
    padding: .3rem .5rem;
    display: flex;
    align-items: center;

    // non-desktop
    @include media-breakpoint-down(md) {
      .JagaContactInfo {
        justify-content: center;

        p, div {
          text-align: center;

          a::before {
            content: $jaga-fa-phone;
            margin-right: .5rem;
            display: inline-block;
            line-height: 1;

            @include jaga-fa;
          }
        }
      }
    }

    // desktop
    @include media-breakpoint-up(lg) {
      .JagaContactInfo {
        justify-content: flex-start;
      }

      .JagaMarketingInfo {
        p, div {
          margin: 0;
          text-align: center;
        }
      }

      .SiteContext {
        justify-content: flex-end;

        cx-site-context-selector, jaga-website-selector {
          flex: 0;
          margin: 0;

          select {
            background: none;
          }
        }
      }
    }
  }

}
