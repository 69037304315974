@import 'src/styles/variables';

cx-page-layout {

  &.CartPageTemplate {
    justify-content: flex-end;
    padding: 0 1rem;

    .TopContent {
      flex-basis: 100%;
    }

    .EmptyCartMiddleContent {
      padding: 2rem 0;

      jaga-link {
        max-width: $jaga-flex-basis-third;
        margin: 2rem;
      }
    }

    .CenterRightContentSlot {
      --cx-flex-basis: 50%;
      max-width: $jaga-max-width-content-half;
      flex-direction: column;

      > * {
        flex: initial;
      }
    }
  }
}
