@import 'src/styles/variables';
@import "src/styles/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";


.quick-order-info-container {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
  }
}

.quick-order-item-list-row {
  padding: 1.25rem 0;
  border: none;
  border-color: var(--cx-color-light);
}

.quick-order-qty {
  text-align: left;
}

.quick-order-remove {
  padding: 0.5rem;
}

.quick-order-image-container {
  padding: 0;

  a {
    display: block;
    height: 100%;
  }

  cx-media {
    &.is-missing {
      min-height: 55px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .quick-order-name {
    @include header-5;
    margin-bottom: .5rem;
  }

  button {
    margin-bottom: 0;
  }

  .quick-order-value {
    font-weight: $jaga-font-weight-bold;
  }

  .quick-order-info-container {
    flex-direction: row;
  }

  .quick-order-code {
    font-weight: $jaga-font-weight-light;
    font-size: 0.7rem;
  }
}

.name {
  font-weight: $jaga-font-weight-bold;
}

.fa-trash-alt {
  cursor: pointer;
}


.entry-counter {
  @include media-breakpoint-down(md) {
    button {
      margin-bottom: 0 !important;
    }
  }

  input {
    text-align: center;

    // avpid native increase/decrease buttons on the numeric field
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &.readonly {
    button {
      display: none;
    }

    input {
      border: none;
      cursor: text;
    }
  }

  &:not(.readonly) {
    display: inline-flex;
    border: solid 1px var(--cx-color-primary);

    input {
      border: solid 1px var(--cx-color-primary);
      border-width: 0 1px;
      padding: 12px 9px;
      max-height: 46px;
      width: 48px;

      &:focus {
        background: var(--cx-color-background);
      }
    }
  }

  button {
    margin: 0;
    max-height: 48px;
    min-width: 40px;
    border: none;
    cursor: pointer;
    color: var(--cx-color-text);
    background-color: var(--cx-color-transparent);
    @include type(3);

    &:hover {
      color: var(--cx-color-primary);
    }

    &:focus {
      color: var(--cx-color, var(--cx-color-primary));
      font-weight: var(--cx-font-weight-bold);
      cursor: pointer;
    }

    &:disabled {
      color: var(--cx-color-light);
    }
  }

  :disabled {
    cursor: not-allowed;
  }
}

.product-desc {
  margin: auto;
}

