@import 'src/styles/variables';

.jaga-product-assignments {
  display: flex;
  flex-direction: row;
  margin: 0 -15px;

  &:hover {
    text-decoration: none;
  }

  ul {
    display: block;
    flex: 1 1 $jaga-flex-basis-half;
    list-style-type: none;
    margin: 0;
    padding: .25rem;
    text-align: center;

    li {
      font-size: .85em;
      line-height: 1.25;
      margin-bottom: .3rem;
    }

    &.jaga-heating-assignments {
      color: $jaga-color-light-red;
    }

    &.jaga-cooling-assignments {
      color: $jaga-color-blue;
    }
  }

  &.jaga-hybrid-assignments {
    ul {
      &.jaga-heating-assignments {
        text-align: start;
      }

      &.jaga-cooling-assignments {
        text-align: end;
      }
    }
  }
}
