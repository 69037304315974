@import "src/styles/variables";

jaga-cart-item {
  @extend %cx-cart-item !optional;

  @include media-breakpoint-down(sm) {
    .cx-name {
      @include header-5;
      margin-bottom: 0.5rem;
    }

    .cx-value {
      font-weight: $jaga-font-weight-bold;
    }

    .cx-info-container {
      flex-direction: row;
    }

    .cx-code {
      font-weight: $jaga-font-weight-light;
      font-size: 0.7rem;
    }

    .cx-compact {
      .cx-image-container {
        cx-media {
          &.is-missing {
            min-height: 150px;
          }
        }
      }
    }
  }

  cx-item-counter:not(.read-only) {
    border-radius: 0;
  }

  .name {
    font-weight: $jaga-font-weight-bold;
  }

  .cx-value {
    padding: 0.3rem 0;
  }

  .fa-trash-alt {
    cursor: pointer;
  }
}
