/* CUSTOM COLORS */
$jaga-color-red: #a42417;
$jaga-color-light-red: #c38383;
$jaga-color-dark-red: #861f13;
$jaga-color-blue: #5359fd;
$jaga-color-card-background: #fafafa;
$jaga-color-dark-grey: #333;
$jaga-color-border-grey: #e1e1e1;
$jaga-color-border-accent: $jaga-color-red;
$jaga-color-black: #000000;
$jaga-color-white: #ffffff;

/* TYPOGRAPHY */
$jaga-font-path: "/assets/fonts/proxima-nova";
$jaga-font-name: "proxima-nova";
$jaga-font-weight-black: 900;
$jaga-font-weight-bold: 700;
$jaga-font-weight-medium: 500;
$jaga-font-weight-normal: 400;
$jaga-font-weight-light: 300;
$jaga-font-weight-thin: 100;
$jaga-font-fallback: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

// body
$jaga-base-font-size: 14px;
$jaga-base-line-height: 1.25rem;

// heading
$jaga-heading-margin-top: 1rem;
$jaga-heading-margin-bottom: 1.25rem;
$jaga-heading-padding-bottom: 0.625rem;
$border-bottom: 1px solid $jaga-color-border-grey;

// h1
$jaga-h1-font-weight: $jaga-font-weight-bold;
$jaga-h1-font-size: 2em;
$jaga-h1-line-height: 1.22;

// h2
$jaga-h2-font-weight: $jaga-font-weight-bold;
$jaga-h2-font-size: 1.5em;
$jaga-h2-line-height: 1.22;

// h3
$jaga-h3-font-weight: $jaga-font-weight-light;
$jaga-h3-font-size: 1.125em;
$jaga-h3-line-height: 1.22;

// h4
$jaga-h4-font-weight: $jaga-font-weight-light;
$jaga-h4-font-size: 1.125em;
$jaga-h4-line-height: 1.22;

// h5
$jaga-h5-font-weight: $jaga-font-weight-bold;
$jaga-h5-font-size: 1em;
$jaga-h5-line-height: 1.22;

/* MISC */
$font-family-sans-serif: $jaga-font-name, $jaga-font-fallback;

/* FONT AWESOME */
$jaga-fa-angle-up: "\f106";
$jaga-fa-angle-down: "\f107";
$jaga-fa-angle-left: "\f104";
$jaga-fa-angle-right: "\f105";
$jaga-fa-angle-double-left: "\f100";
$jaga-fa-angle-double-right: "\f101";
$jaga-fa-minus: "\f068";
$jaga-fa-phone: "\f095";
$jaga-fa-plus: "\f067";
$jaga-fa-question: "\f128";
$jaga-fa-shopping-cart: "\f07a";
$jaga-fa-user: "\f007";

/* BOOTSTRAP */
$bootstrap-padding: 15px;

/* LAYOUT */
$sm: 540px;
$md: 768px;
$lg: 1024px;

$jaga-max-width-wide: 1700px;

$jaga-max-width-content: 1140px;
$jaga-max-width-content-three-quarters: 855px;
$jaga-max-width-content-two-thirds: 760px;
$jaga-max-width-content-half: 570px;
$jaga-max-width-content-third: 380px;
$jaga-max-width-content-quarter: 285px;

$jaga-flex-basis-half: 50%;
$jaga-flex-basis-third: 33%;
$jaga-flex-basis-two-thirds: 66%;
$jaga-flex-basis-quarter: 25%;
$jaga-flex-basis-fifth: 20%;
$jaga-flex-basis-three-quarters: 75%;

$jaga-paragraph-max-width-content: $jaga-max-width-content;
$jaga-paragraph-max-width-two-thirds: $jaga-max-width-content-two-thirds;
$jaga-paragraph-max-width-third: 430px;

$jaga-header-height-desktop: 170px;
$jaga-header-height-mobile: 97px;
