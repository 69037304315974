jaga-added-to-cart-dialog {
  @extend %cx-added-to-cart-dialog !optional;

  @include media-breakpoint-down(lg) {
    .cx-dialog-actions,
    .cx-dialog-item {
      padding: 1rem 1.5rem;
    }
  }

  .btn {
    font-weight: var(--cx-font-weight-normal);
  }

  .cx-dialog-row {
    padding: 0 0.875rem 0.875rem;
  }

  .cx-dialog-item .cx-image-container .jaga-product-image {
    max-width: 100%;
  }
}
