@import 'src/styles/variables';
@import 'src/styles/mixins';

jaga-accordion-paragraph {
  display: flex;
  flex-direction: column;

  .title {
    border: 0;
    display: flex;
    justify-content: space-between;
    background: var(--cx-color-light);
    color: var(--cx-color-primary);
    font-weight: var(--cx-font-weight-bold);
    padding: 0.75rem 1rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: .5rem;

    // after plus - minus
    &::after {
      @include jaga-fa;
    }

    &:not(active)::after {
      content: $jaga-fa-plus;
    }

    &.active::after {
      content: $jaga-fa-minus;
    }

    &:hover {
      text-decoration: none;
      @include cx-darken(88);
    }
  }
  .content {
    padding: 1rem;
  }
}
