@import 'src/styles/variables';

cx-page-layout {
  &.ErrorPageTemplate {
    .TopContent {
      padding: 2rem 0;

      jaga-paragraph {
        text-align: left;
      }

      jaga-link {
        max-width: $jaga-flex-basis-third;
        margin: 2rem;
      }
    }
  }
}
