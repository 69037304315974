@import 'src/styles/variables';

@mixin jaga-product-assignments-responsive {
  @include media-breakpoint-up(md) {
    .jaga-product-assignments {
      display: block;
      margin: 1rem 0 0 0;

      ul {
        display: inline-block;
        margin: 0;
        padding: 0;

        li {
          text-align: start;
        }
      }

      &.jaga-hybrid-assignments {
        ul {
          &.jaga-heating-assignments {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}
