jaga-mini-cart {

  margin-left: 0;
  margin-right: 0;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  cx-icon {
    &::before {
      display: block;
      width: 35px;
      height: 35px;
      padding: 9px 8px;
      border: 1px currentColor solid;
      border-radius: 100%;
    }
  }

  .count {
    position: absolute;
    z-index: 1;
    bottom: -.3rem;
    right: -.4rem;
    display: flex;
    min-width: 1.25rem;
    height: 1.25rem;
    padding: 0 .25rem;
    font-size: .85714rem;
    line-height: inherit;
    color: white;
    border-radius: .75rem;
    background-color: #ca0201;
    align-items: center;
    justify-content: center;
  }

  .total, .cart {
    display: block;
    font-size: .9em;
    margin-top: 0.35rem;
    text-align: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .jaga-cart-icon-wrapper {
    position: relative;
  }

}
