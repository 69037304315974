@import 'src/styles/variables';

jaga-contact {
  padding-top: 1rem;

  div {
    background-color: var(--cx-color-light);

    cx-icon {
      color: $jaga-color-red;
    }
  }
}
