@import "../../../variables";
@import "../../../mixins";

cx-add-to-cart,
jaga-add-to-cart {
  @extend %cx-add-to-cart !optional;

  form {
    display: flex;

    cx-item-counter {
      min-height: 48px;
    }

    .quantity {
      margin: 0 1rem 0 0;

      label {
        display: none;
      }
    }
  }

  button[type="submit"] {
    &.btn:not(:disabled):not(.disabled) {
      color: var(--cx-color-transparent);
      min-width: 5rem;
      flex: 0;
      font-size: 0;
    }

    &::before {
      content: $jaga-fa-shopping-cart;
      font-size: 1.125rem;
      display: block;
      color: var(--cx-color-inverse);

      @include jaga-fa;
    }
  }
}
