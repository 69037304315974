@import 'src/styles/variables';

$jaga-font-weight-fa: 900;

/* Use this mixin if you want to use font awesome on any random css selector
 * Example: button with shopping cart icon
 * .btn {
 *    &::before {
 *      content: "\f07a";
 *      @include jaga-fa;
 *    }
 *  }
 */
@mixin jaga-fa {
  font-family: "Font Awesome 5 Free";
  font-weight: $jaga-font-weight-fa;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}


/* HEADER */
@mixin header {
  margin-top: $jaga-heading-margin-top;
  margin-bottom: $jaga-heading-margin-bottom;
  padding-bottom: $jaga-heading-padding-bottom;
  text-transform: uppercase;
  text-align: left;

  border-bottom: 1px solid $jaga-color-border-grey;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 30px;
    bottom: 0;
    left: 0;
    background-color: $jaga-color-border-accent;
  }
}

@mixin header-1 {
  font-size: $jaga-h1-font-size;
  font-weight: $jaga-h1-font-weight;
  line-height: $jaga-h1-line-height;
  @include header;
}

@mixin header-2 {
  font-size: $jaga-h2-font-size;
  font-weight: $jaga-h2-font-weight;
  line-height: $jaga-h2-line-height;
  @include header;
}

@mixin header-3 {
  font-size: $jaga-h3-font-size;
  font-weight: $jaga-h3-font-weight;
  line-height: $jaga-h3-line-height;
  @include header;
}

@mixin header-5 {
  font-size: $jaga-h5-font-size;
  font-weight: $jaga-h5-font-weight;
  line-height: $jaga-h5-line-height;
  @include header;
}
