jaga-address-book {
  @extend %cx-address-book;

  cx-card {
    .cx-card {
      .card-header {
        background: var(--jaga-color-accent);
      }
    }
  }
}
