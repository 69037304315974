
jaga-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &:focus {
    outline: none;
  }

  &.start-navigating {
    @extend %progress-bar, %progress-bar-loading !optional;

    &::after {
      background-color: var(--jaga-color-accent);
    }
  }

  &.stop-navigating {
    @extend %progress-bar, %progress-bar-loaded !optional;

    &::after {
      background-color: var(--cx-color-light);
    }
  }

  &.start-navigating,
  &.stop-navigating {
    &::before,
    &::after {
      z-index: 1030;
    }
  }
}
