@import 'src/styles/variables';

jaga-quick-order-search {
  position: relative;
  display: inline-block;

  label {
    display: flex;

    input {
      flex: 1;
    }

    cx-icon {
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .results {
    position: absolute;
    left: 0;
    background: $jaga-color-white;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 4px rgb(0 0 0 / 15%);

    .suggestions {
      display: flex;
      flex-direction: column;

      a {
        display: block;
        line-height: 2rem;
        padding: 0.5rem 1rem;

        &:focus,
        &:hover {
          background: var(--cx-color-light);
          text-decoration: none;
        }
      }
    }
  }
}
