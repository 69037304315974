@import "src/styles/variables";
@import "@spartacus/styles/index";

$card-border-radius: 2px;

jaga-shipping-address {
  @extend %cx-shipping-address !optional;

  background-color: transparent;

  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  @include media-breakpoint-up(md) {
    .jaga-country-select {
      width: 50%;
    }

    .jaga-title-code {
      width: 30%;
    }
  }

  .cx-checkout-title {
    margin: 0 0 1rem 0;
    padding: 1rem 0;
    text-transform: uppercase;
    font-weight: var(--cx-font-weight-bold);
  }

  .cx-checkout-text {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .cx-checkout-body {
    .jaga-add-address-link {
      .btn-link {
        font-weight: var(--cx-font-weight-bold);
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 0.9em;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .cx-checkout-btns {
    @include media-breakpoint-down(md) {
      padding: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .cx-shipping-address-card {
    padding-top: 1rem;
    padding-bottom: 0;

    .cx-shipping-address-card-inner {
      .cx-card {
        border: 0;
        border-radius: $card-border-radius;
        background-color: $jaga-color-card-background;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

        &:hover {
          box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
        }

        .card-header {
          background: $jaga-color-red;
          text-transform: uppercase;
          line-height: normal;
          border-radius: $card-border-radius $card-border-radius 0 0;
        }

        .cx-card-body {
          .cx-card-title {
            font-size: 1em;
            text-transform: uppercase;
            font-weight: var(--cx-font-weight-bold);
            color: var(--cx-color-secondary);
          }
        }

        .cx-card-actions {
          padding: 0;

          a {
            color: var(--cx-color-secondary);
            font-weight: bold;
            font-size: 0.8em;
            height: auto;
            padding: 6px 8px;
            position: relative;
            text-transform: uppercase;
            text-decoration: none;
          }
        }
      }
    }
  }

  jaga-address-form {
    background: white;
    margin: 1rem 0;
    padding: 1rem 0;
  }
}
