/* You can add global styles to this file, and also import other style files */

$useTabsOnPDP: false;
$primary: #000;
$secondary: #3d3e43;
$light: #efefef;
$visual-focus: transparent;

$styleVersion: 2211;
$skipComponentStyles: (cx-global-message, cx-register, cx-reset-password);
@import "@spartacus/styles/index";
@import "@spartacus/asm";
@import "@spartacus/cart";
@import "@spartacus/checkout";
@import "@spartacus/order";
@import "@spartacus/user";

// Custom theme
@import "styles/app";
@import "styles/root";
