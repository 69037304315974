cx-site-context-selector {
  margin-left: 20px;

  label {
    position: relative;

    select {
      background: none;
      font-weight: var(--cx-font-weight-normal);
      z-index: 1;
    }

    span {
      @include sr-only();
    }

    cx-icon {
      position: absolute;
      right: 0;
      margin: 0;
      z-index: 0;
    }
  }
}
