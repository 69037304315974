@import 'src/styles/variables';

$card-border-radius: 2px;

jaga-payment-type {
  padding: 0 1rem;

  .cx-payment-type-container,
  .po-number-container {
    @include media-breakpoint-up(md) {
      max-width: 15rem;
    }
  }

  .cx-checkout-btns {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .btn-action {
      @include media-breakpoint-down(md) {
        margin: 0 0 1rem;
      }
    }
  }

  .jaga-billing-address {
    .cx-card {
      border-radius: $card-border-radius;
      background-color: $jaga-color-card-background;

      .cx-card-body {
        .cx-card-label-bold {
          font-weight: var(--cx-font-weight-bold);
        }
      }
    }
  }

}
