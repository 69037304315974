@import '../../../../variables';

jaga-product-references {
  .JagaProductReferences  {
    @extend %cx-product-references;
    .item {
      display: flex;
      flex-direction: column;

      a, a:hover {
        text-decoration: none;

        h4 {
          font-size: 1.125rem;
          font-weight: $jaga-font-weight-bold;
          min-height: auto;
        }

        .price {
          font-weight: $jaga-font-weight-bold;
        }
      }

      cx-item-counter {
        &:not(.readonly) {
          input {
            @include media-breakpoint-up(sm) {
              max-width: 48px;
            }
          }
        }
      }
    }
  }

}
