@import "../../../variables";

jaga-cart-totals {
  @extend %cx-cart-totals;

  .btn {
    &.btn-primary {
      font-weight: var(--cx-font-weight-bold);
    }
  }

  jaga-order-summary {
    padding: 0;
    font-size: 1em;
  }

  .button-wrapper {
    margin-top: 1rem;

    > button {
      width: 100%;

      &:first-child {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    jaga-order-summary {
      .cx-summary-row {
        padding: 0.5rem 0;
      }
    }

    .button-wrapper {
      display: flex;

      > .btn {
        flex: 1;
        font-size: 1em;
        text-transform: uppercase;

        &:first-child {
          margin-right: 1rem;
        }
      }

      button.btn.btn-secondary {
        display: inline-block;
      }
    }
  }
}
