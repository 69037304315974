@import "src/styles/variables";

$modal-min-width-lg: $jaga-max-width-content * 0.9 !important;
$modal-max-width-lg: $jaga-max-width-content * 0.9 !important;

@include media-breakpoint-up(md) {
  .modal {
    .modal-lg {
      min-width: $modal-min-width-lg;
      max-width: $modal-max-width-lg;
    }
  }
}
