@import 'src/styles/variables';
@import 'src/styles/mixins';

cx-pagination {
  a {
    border: none;

    &:last-child {
      border: 0;
    }

    &.start, &.previous,
    &.next, &.end {
      &::after {
        @include jaga-fa;
        font-size: .875rem;
      }
    }

    &.start::after {
      content: $jaga-fa-angle-double-left;
    }

    &.previous::after {
      content: $jaga-fa-angle-left;
    }

    &.next::after {
      content: $jaga-fa-angle-right;
    }

    &.end::after {
      content: $jaga-fa-angle-double-right;
    }

  }

  @include media-breakpoint-up(md) {
    align-items: center;

    a {
      width: 2rem;
      height: 2rem;
    }
  }
}
