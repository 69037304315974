@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/theme/components/product/assignments/mixins";

jaga-product-grid-item {
  @extend %cx-product-grid-item;

  .jaga-product-grid-item {
    border-bottom: 1px solid var(--cx-color-secondary);
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    jaga-add-to-cart {
      form {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .quantity {
          margin: 0 20px 0 0;

          label,
          .info {
            display: none;
          }
        }

        button[type=submit] {
          &.btn:not(:disabled):not(.disabled) {
            color: var(--cx-color-transparent);
            min-width: 5rem;
            flex: 0;
            font-size: 0;
          }

          &::before {
            content: $jaga-fa-shopping-cart;
            font-size: 1.125rem;
            display: block;
            color: var(--cx-color-inverse);

            @include jaga-fa;
          }
        }

        @include media-breakpoint-up(md) {
          justify-content: space-between;

          cx-item-counter:not(.readonly) input {
            max-width: 58px;
          }
        }
      }
    }

    .cx-product-image {
      margin: 1rem 0;
    }

    .cx-product-group-description {
      display: block;
      font-size: 1.5em;
      font-weight: $jaga-font-weight-bold;
      text-align: center;
      margin: 0 0 .25rem 0;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cx-product-name {
      height: auto;
      font-weight: $jaga-font-weight-normal;
    }

    .cx-product-price-container {
      margin-top: auto;
      flex-grow: 0;

      .cx-product-price {
        font-size: 1rem;
      }
    }
  }

}
