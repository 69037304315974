
jaga-update-password-form {
  @extend %cx-update-password-form !optional;

  .form-group {
    margin-bottom: 0;

    &:last-child {
      margin-top: 1.5rem;
    }
  }

  input {
    padding-left: .2rem;
  }

}
