@import 'src/styles/variables';

.ContentPage6Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-content;
  margin: 0 auto;
  padding: 2rem 10px 0;

  cx-page-slot {
    flex-basis: 100%;
    margin: 0 auto;

    &.Section3 {
      display: block;
      width: 100%;
      overflow-x: auto;
    }

    &.Section9 {
      padding: 1.5rem 0;
    }
  }

  @include media-breakpoint-up(md) {
    cx-page-slot {
      &.Section4A, &.Section4B, &.Section4C,
      &.Section5A, &.Section5B, &.Section5C,
      &.Section6A, &.Section6B, &.Section6C {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section7A, &.Section7B, &.Section7C, &.Section7D,
      &.Section8A, &.Section8B, &.Section8C, &.Section8D {
        flex-basis: $jaga-flex-basis-quarter;
      }
    }
  }
}
