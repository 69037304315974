@import "src/styles/variables";
@import "src/styles/mixins";
@import '../assignments/mixins';

jaga-product-list-item {
  @extend %cx-product-list-item;

  .jaga-product-list-item {
    border-bottom: 1px solid var(--cx-color-secondary);
    padding-bottom: 1rem;

    jaga-add-to-cart {
      form {
        display: flex;
        justify-content: center;

        .quantity {
          margin: 0 20px 0 0;

          label,
          .info {
            display: none;
          }
        }

        button[type=submit] {
          &.btn:not(:disabled):not(.disabled) {
            color: var(--cx-color-transparent);
            font-size: 0;
            min-width: 5rem;
            flex: 0;
          }

          &::before {
            content: $jaga-fa-shopping-cart;
            font-size: 1.125rem;
            color: var(--cx-color-inverse);
            display: block;

            @include jaga-fa;
          }
        }
      }
    }

    .cx-product-image {
      margin: 1rem 0;
    }

    .cx-product-group-description {
      display: block;
      text-align: center;
      font-size: 1.5em;
      font-weight: $jaga-font-weight-bold;
      margin: 0 0 .25rem 0;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    .cx-product-name {
      height: auto;
      font-weight: $jaga-font-weight-normal;
    }

    @include jaga-product-assignments-responsive;

    .cx-product-price {
      font-size: 1rem;
    }

    .cx-product-summary {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    .jaga-product-list-item {
      padding: 1rem 0;

      .cx-product-image {
        margin: 0;
      }

      .cx-product-name {
        font-size: 1rem;
      }

      .cx-product-summary {
        display: block;
      }

      jaga-add-to-cart {
        form {
          justify-content: unset;

          button[type=submit] {
            &.btn:not(:disabled):not(.disabled) {
              font-size: 1.125rem;
              color: var(--cx-color-inverse);
              flex: 1;
            }

            &::before {
              content: "";
              display: none;
            }
          }
        }
      }
    }
  }

}
