
cx-applied-coupons {
  .cx-coupon-apply,
  .cx-applied-coupon-code {
    padding: .5rem 1rem;
    font-weight: var(--cx-font-weight-bold);
  }

  > div {
    display: flex;
    flex-direction: column;

    .cx-applied-coupon-title {
      margin-top: .5rem;
      padding: 0 1rem;
      text-transform: uppercase;
      font-weight: var(--cx-font-weight-bold);
    }

    .cx-coupon-card {
      border-radius: 0;
      margin: .5rem 0;
      padding: .5rem 1rem;

      .cx-applied-coupon-code {
        padding: 0;
      }
    }
  }
}
