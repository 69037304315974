jaga-order-summary {
  @extend %cx-order-summary !optional;

  padding: 0;
  text-transform: uppercase;

  h4,
  .cx-summary-partials {
    padding: 0.5rem 1rem;
    background-color: var(--cx-color-light);
  }

  .jaga-discount {
    color: var(--cx-color-success);
  }
}
