.user-form {
  --cx-max-width: 100%;

  display: block;
  width: 100%;
}

.AccountPageTemplate {
  cx-page-slot {
    padding: 0;

    &.has-components {
      padding: 1.5rem 1rem;
    }
  }

  .BodyContent {
    padding: 1.5rem 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
