cx-anonymous-consent-open-dialog {
  margin: 0;

  .btn-link {
    padding: 0;
    color: var(--cx-color-primary);
    font-size: 1em;
    min-height: auto;
    height: fit-content;

    &:not(:hover) {
      text-decoration: none;
    }

    &:hover {
      color: var(--cx-color-primary);
    }

    &:focus {
      box-shadow: none;
    }
  }
}
