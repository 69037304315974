@import 'src/styles/variables';

.ContentPage2Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-content;
  margin: 0 auto;
  padding: 2rem 10px 0;

  cx-page-slot {
    flex-basis: 100%;
    margin: 0 auto;

  }

  @include media-breakpoint-up(md) {
    cx-page-slot {
      &.Section4A, &.Section4B,
      &.Section5A, &.Section5B,
      &.Section6A, &.Section6B {
        flex-basis: $jaga-flex-basis-half;
      }
    }
  }
}
