@import 'src/styles/variables';
@import 'src/styles/mixins';

jaga-added-to-cart-dialog-carousel {
  @extend %cx-product-references;
  display: block;
  width: 100%;
  padding-bottom: 0;

  .item {
    display: flex;
    flex-direction: column;
  }

  .name {
    text-transform: uppercase;
  }

  .price {
    font-weight: $jaga-font-weight-bold;
  }

  cx-carousel {
    h3 {
      @include header-2;
      font-weight: $jaga-font-weight-bold;
      margin-top: 1rem;
    }

    .carousel-panel {
      .slides {
        .slide:not(.active) {
          .item {
            display: none;
          }
        }

        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    jaga-add-to-cart {
      form {
        justify-content: space-evenly;
      }
    }
  }

  cx-media {
    &.is-missing {
      min-height: 155px;
    }
  }
}
