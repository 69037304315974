cx-page-layout.LoginPageTemplate {
  padding-bottom: 0;

  cx-page-slot {
    align-items: flex-start;
    padding: 1.5rem 1rem 0;

    &.HeaderContentSlot {
      --cx-max-width: 100%;

      &:not(.has-components) {
        padding: 0;
      }
    }

    &.LeftContentSlot,
    &.RightContentSlot {
      &:not(.has-components) {
        padding: 0;
      }
    }

    &.LeftContentSlot {
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      padding: 1rem;
      justify-content: start;

      jaga-login-form-component {
        @include media-breakpoint-up(lg) {
          padding-right: 1rem;
        }
      }

      jaga-reset-password {
        --cx-max-width: 100%;
      }
    }
  }
}
