@import 'src/styles/variables';
@import '../assignments/mixins';

jaga-product-intro {
  @extend %cx-product-intro;

  @include media-breakpoint-up(lg) {
    padding: 0 0 .5rem 0;
  }

  .h2 {
    font-weight: $jaga-font-weight-bold;
  }

  .product-info-container {
    display: flex;

    .product-info {
      flex: 1 1 70%;

      .name {
        font-size: 1rem;
        font-weight: $jaga-font-weight-bold;
      }
    }

    .product-logos {
      flex: 1 1 30%;
      display: flex;
      justify-content: space-evenly;

      cx-media {
        display: block;
        margin: 0 .25rem;

        img {
          max-width: 55px;
        }
      }
    }
  }

  @include jaga-product-assignments-responsive;

  .btn-cta {
    text-transform: uppercase;
  }
}
