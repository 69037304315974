@import 'src/styles/variables';
@import 'src/styles/mixins';

.ProductDetailsPageTemplate {
  cx-page-slot {
    &.Summary {

      jaga-product-images {
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }

      jaga-add-to-cart {
        padding: 0;
      }

      jaga-product-summary {
        text-align: center;
      }

      jaga-add-to-cart {
        button[type=submit].btn:not(:disabled):not(.disabled) {
          flex: 1;
        }
      }

      @include media-breakpoint-up(md) {
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 0;
        grid-template-columns: 1fr .5fr .5fr;
        grid-template-rows: repeat(3, auto) 1fr;

        jaga-product-images {
          grid-row: 1 / span 4;
          grid-column: 1;
        }

        jaga-product-intro {
          grid-column: 2 / span 2;
        }

        jaga-product-variants {
          grid-row: 2;
          grid-column: 2 / span 2;
        }

        jaga-product-summary {
          grid-row: 3;
          grid-column: 2;
          padding: 0.3rem 0 0 0;
          align-self: flex-start;
          text-align: start;
        }

        jaga-add-to-cart {
          grid-row: 3;
          grid-column: 3;
          padding: 0;
          justify-self: end;

          button[type=submit].btn:not(:disabled):not(.disabled) {
            flex: 0
          }
        }

        jaga-product-delivery-time-description {
          grid-row: 4;
          grid-column: 2 / span 2;
        }
      }
    }

    &.Details {
      @extend .container !optional;
      padding-bottom: 2rem;

      @include media-breakpoint-up(sm) {
        jaga-product-details {
          flex-basis: $jaga-flex-basis-two-thirds;
          max-width: $jaga-max-width-content-two-thirds;
        }

        jaga-product-manuals {
          flex-basis: $jaga-flex-basis-third;
          max-width: $jaga-max-width-content-third;
          padding-left: 2rem;
        }
      }

    }

    &.MandatoryCrossSelling {
      max-width: $jaga-max-width-content;
      margin: auto;
    }
  }
}
