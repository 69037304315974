@import 'src/styles/variables';

.ContentPage4Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-content;
  margin: 0 auto;

  cx-page-slot {
    flex-basis: 100%;
    margin: 0 auto;

    &.Section1 {
      &.has-components {
        padding-bottom: 1.5rem;
      }
    }

    &.Section2 {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      jaga-paragraph {
        text-align: center;
        padding: 1rem;

        h2 {
          text-align: center;
        }
      }
    }

    &.Section3A, &.Section3B {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: nowrap;

      > * {
        flex: 0;
      }

      jaga-paragraph {
        text-align: center;
        padding: 2rem 1rem 1rem;
        margin: 0 auto;
      }

      jaga-banner {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 150px;
      }
    }

    &.Section4A, &.Section4B,
    &.Section5A, &.Section5B {
      align-items: center;

      jaga-paragraph {
        padding: 1.5rem;
        text-align: center;
      }

      @include media-breakpoint-up(md) {
        &.has-components {
          margin: 2rem 0;
        }
      }
    }

    &.Section4B {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      jaga-image {
        max-width: 150px;
      }

      @include media-breakpoint-up(md) {
        jaga-paragraph {
          text-align: left;
        }
      }
    }

    &.Section5A {
      @include media-breakpoint-up(md) {
        jaga-paragraph {
          text-align: right;
        }
      }
    }

    &.Section6A,
    &.Section6B,
    &.Section6C {
      max-width: $jaga-max-width-content-third;

      &.has-components {
        padding: 2rem 0;
      }

      jaga-paragraph {
        text-align: center;
        margin-top: 1rem;
      }
    }

    &.Section6A {
      jaga-image {
        margin-right: 0.5rem;
      }
    }

    &.Section6B {
      jaga-image {
        margin: 0 0.25rem;
      }
    }

    &.Section6C {
      jaga-image {
        margin-left: 0.5rem;
      }
    }

    &.Section9A, &.Section9B {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      jaga-paragraph {
        text-align: center;
        padding: 2rem 1rem 1rem;
        margin: 0 auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    cx-page-slot {
      &.Section3A, &.Section3B,
      &.Section9A, &.Section9B {
        flex-basis: $jaga-flex-basis-half;
      }

      &.Section4A,
      &.Section5B,
      &.Section10A,
      &.Section11B {
        flex-basis: $jaga-flex-basis-two-thirds;
      }

      &.Section4B,
      &.Section5A,
      &.Section6A, &.Section6B, &.Section6C,
      &.Section10B,
      &.Section11A,
      &.Section12A, &.Section12B, &.Section12C {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section7A, &.Section7B, &.Section7C, &.Section7D,
      &.Section13A, &.Section13B, &.Section13C, &.Section13D {
        flex-basis: $jaga-flex-basis-quarter;
      }
    }
  }
}

