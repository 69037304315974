@import "../../../../variables";

jaga-product-summary {
  @extend %cx-product-summary;

  justify-content: center;

  .price {
    margin: 0 0 1rem 0;
    font-size: 2.25em;
    font-weight: $jaga-font-weight-normal;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

}
