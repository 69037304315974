
cx-facet-list {
  &.dialog {
    z-index: 1030; // sticky header bootstrap class has z-index of 1020
  }

  .inner {
    padding: 0 .5rem;
  }
}
