@import 'src/styles/variables';

jaga-product-variants {
  @extend %cx-product-variants !optional;

  .variant-section {
    padding: 1rem 0;

    @include media-breakpoint-up(lg) {
      padding: 2.5rem 0;
    }
  }

  .variant-generic-selector {
    span {
      color: var(--cx-color-secondary);
      text-transform: uppercase;
      font-weight: $jaga-font-weight-bold;
      margin-bottom: .5rem;
    }
  }
}
