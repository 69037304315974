@import 'src/styles/variables';

.LandingPage2Template {
  cx-page-slot {
    margin: auto;

    &.Section1 {
      max-width: $jaga-max-width-wide;
      margin-top: 0;
    }

    &.Section2 {
      &.has-components {
        padding: 1rem;
      }

      max-width: $jaga-max-width-content;

      jaga-paragraph {
        text-align: center;
      }

      @include media-breakpoint-up(sm) {
        &.has-components {
          padding: 3rem 1rem;
        }

        jaga-paragraph {
          max-width: 720px;
          margin: 0 auto;
        }
      }
    }

    // Section 3
    @include media-breakpoint-up(sm) {
      &.Section3A, &.Section3B {
        flex-basis: $jaga-flex-basis-half;
      }
    }

    // Section 4
    @include media-breakpoint-up(xl) {
      &.Section4A {
        flex-basis: $jaga-flex-basis-three-quarters;
        max-width: $jaga-flex-basis-three-quarters;
      }

      &.Section4B {
        flex-basis: $jaga-flex-basis-quarter;
        max-width: $jaga-flex-basis-quarter;
      }
    }

    // Section 5
    @include media-breakpoint-up(xl) {
      &.Section5A {
        flex-basis: $jaga-flex-basis-quarter;
      }

      &.Section5B {
        flex-basis: $jaga-flex-basis-three-quarters;
      }
    }

    &.Section6A, &.Section6B, &.Section6C {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      max-width: $jaga-max-width-content-third;
      padding: 0.5rem 1rem;
      margin: 0 auto;

      jaga-banner {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }

      jaga-paragraph {
        text-align: center;
      }

      @include media-breakpoint-up(sm) {
        flex-basis: $jaga-flex-basis-third;
      }
    }

    @include media-breakpoint-up(sm) {
      &.Section6A, &.Section6B, &.Section6C {
        margin: 0;
      }

      &.Section6A {
        margin-left: auto;
      }

      &.Section6C {
        margin-right: auto;
      }
    }

    &.Section7 {
      &.has-components {
        padding: 1rem;

        @include media-breakpoint-up(sm) {
          padding: 2rem;
        }
      }

      jaga-link {
        max-width: 720px;
      }

    }

    // Section 8
    @include media-breakpoint-up(sm) {
      &.Section8A, &.Section8B, &.Section8C, &.Section8D {
        flex-basis: $jaga-flex-basis-quarter;
      }
    }

    &.Section9 {
      padding: 0 1rem;
      max-width: $jaga-max-width-content;

      @include media-breakpoint-up(sm) {
        &.has-components {
          padding-top: 4rem;
        }
      }
    }

    &.Section10A {
      @include media-breakpoint-up(lg) {
        flex-basis: $jaga-flex-basis-two-thirds;
      }
    }

    &.Section10B {
      align-items: center;
      padding: 1rem;

      @include media-breakpoint-up(sm) {
        padding: 1rem 2rem;
      }

      @include media-breakpoint-up(lg) {
        flex-basis: $jaga-flex-basis-third;
      }

      jaga-paragraph {
        max-width: $jaga-paragraph-max-width-two-thirds;
        text-align: center;

        h2 {
          font-weight: $jaga-font-weight-light;
        }
      }

      .title {
        font-weight: bold;
      }

      @include media-breakpoint-up(xl) {
        flex-basis: $jaga-flex-basis-quarter;
        max-width: $jaga-flex-basis-quarter;
      }
    }

    &.Section11A, &.Section11B {
      &.has-components {
        padding: 1rem;
      }

      @include media-breakpoint-up(sm) {
        flex-basis: $jaga-flex-basis-half;
      }
    }

    @include media-breakpoint-up(sm) {
      &.Section11A {
        margin-left: auto;
        margin-right: 0;
      }

      &.Section11B {
        margin-left: 0;
        margin-right: auto;
      }
    }

    &.Section12 {
      &.has-components {
        padding: 0 1rem;
      }

      max-width: $jaga-max-width-content;

      @include media-breakpoint-up(sm) {
        &.has-components {
          padding-top: 4rem;
        }
      }
    }

    &.Section13 {
      &.has-components {
        @include media-breakpoint-up(sm) {
          padding-bottom: 4rem;
        }
      }

      jaga-product-carousel {
        max-width: $jaga-max-width-content;
      }
    }

    // Section 14
    @include media-breakpoint-up(sm) {
      &.Section14A, &.Section14B, &.Section14C {
        flex-basis: $jaga-flex-basis-third;
        margin: 0;
      }
      &.Section14A {
        margin-left: auto;
      }

      &.Section14C {
        margin-right: auto;
      }
    }

    // Section 15
    @include media-breakpoint-up(sm) {
      &.Section15A, &.Section15B, &.Section15C, &.Section15D {
        margin: 0;
        flex-basis: $jaga-flex-basis-quarter;
      }
      &.Section15A {
        margin-left: auto;
      }
      &.Section15D {
        margin-right: auto;
      }
    }

    &.Section17 {
      &.has-components {
        padding: 1rem 1rem 0;
      }

      jaga-paragraph {
        max-width: $jaga-max-width-content;
      }

      @include media-breakpoint-up(sm) {
        &.has-components {
          padding-top: 4rem;
        }
      }
    }

    &.Section18A, &.Section18B, &.Section18C, &.Section18D {
      &.has-components {
        padding: 1rem;
      }

      cx-media {
        justify-content: center;

        img {
          max-width: fit-content;
        }
      }

      @include media-breakpoint-up(sm) {
        flex-basis: $jaga-flex-basis-quarter;
        max-width: $jaga-max-width-content-quarter;
        margin: 0;
      }
    }

    @include media-breakpoint-up(sm) {
      &.Section18A {
        margin-left: auto;
      }

      &.Section18D {
        margin-right: auto;
      }
    }

  }
}
