@import '../../../../variables';

cx-product-facet-navigation {
  .btn:not(:disabled):not(.disabled) {
    font-size: .875rem;
    font-weight: $jaga-font-weight-bold;
    text-transform: uppercase;
    color: $jaga-color-dark-grey;
  }
}
