jaga-delivery-mode {
  @extend %cx-delivery-mode !optional;

  background-color: var(--cx-color-white);
  padding: 0 1rem;

  .remarks {
    width: 100%;
    resize: none;
    border: 1px solid var(--cx-color-primary);
    padding: 0.5rem;
  }

  .form-check {
    .cx-delivery-label {
      margin: 0 auto 0.75rem 0.75rem;
    }
  }
}
