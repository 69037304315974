@import "../../../../variables";

jaga-product-manuals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    flex-basis: $jaga-flex-basis-half;
  }

  a {
    display: block;
    text-align: center;
    font-size: .9em;
    text-transform: uppercase;
    font-weight: $jaga-font-weight-bold;
    color: var(--cx-color-secondary);
    padding: 0 .5rem;
    max-width: 14.3rem;
    margin-bottom: 2rem;

    cx-media {
      margin: 0 auto;
      justify-content: center;

      &.is-missing {
        height: initial;
        width: initial;
      }

      &:not(.is-missing) {
        img {
          margin-bottom: 0.625rem;
        }
      }
    }
  }
}
