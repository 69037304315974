@import 'src/styles/variables';

cx-active-facets {
  padding: .8rem 0 0 0;

  @include media-breakpoint-up(md) {
    padding: 0 .5rem;
  }

  h4 {
    padding: 0;
    margin: 0 0 0.5rem 0;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: $jaga-font-weight-bold;
  }
}
