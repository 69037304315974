@import '../variables';

/*100	Thin (Hairline)*/
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova-thin.otf");
  font-weight: $jaga-font-weight-thin;
  font-style: normal;
}

/*300	Light*/
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova-light.otf");
  font-weight: $jaga-font-weight-light;
  font-style: normal;
}

/*400	Normal*/
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova.otf");
  font-weight: $jaga-font-weight-normal;
  font-style: normal;
}
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova-it.otf");
  font-weight: $jaga-font-weight-normal;
  font-style: italic;
}

/*500	Medium*/
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova-medium.otf");
  font-weight: $jaga-font-weight-medium;
  font-style: normal;
}

/*700	Bold*/
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova-bold.otf");
  font-weight: $jaga-font-weight-bold;
  font-style: normal;
}

/*900	Black (Heavy)*/
@font-face {
  font-family: $jaga-font-name;
  src: url("#{$jaga-font-path}/proxima-nova-black.otf");
  font-weight: $jaga-font-weight-black;
  font-style: normal;
}
