@import "../../../variables";
@import "../../../mixins";

jaga-login {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
    }

    .login {
      margin: 0.35rem 0 0 0;
      font-size: 0.9em;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  cx-icon {
    &.fa-user {
      display: block;
      width: 35px;
      height: 35px;
      padding: 8px 9px;
      border: 1px currentColor solid;
      border-radius: 100%;

      &::before {
        margin-left: 1px;
      }
    }
  }

  jaga-acc-nav-ui.flyout > nav {
    > h5 {
      padding: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::before {
      display: block;
      content: $jaga-fa-user;
      font-size: 14px;
      width: 35px;
      height: 35px;
      margin: 0 auto;
      padding: 6px 10px;
      border: 1px currentColor solid;
      border-radius: 100%;

      @include jaga-fa;
    }
  }
}
