cx-page-layout.ProductGridPageTemplate,
cx-page-layout.ProductListPageTemplate {
  padding-top: 2rem;

  .cx-page-section {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      padding-top: 1rem;
    }
  }

  .ProductLeftRefinements {
    margin: 0 auto;
    max-width: $sm;
    padding: 1rem 1rem 0;

    @media screen and (min-width: $md) {
      max-width: 720px;
    }

    @media screen and (min-width: $lg) {
      max-width: 25%;
    }
  }
}
