
cx-global-message {
  position: absolute;
  display: block;
  width: 100%;
  top: 96px;
  z-index: 2;

  @include media-breakpoint-up(md) {
    top: 110px;
  }

  @include media-breakpoint-up(lg) {
    top: 170px;
  }
}
