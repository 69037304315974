:root {
  --transparant: transparant;

  /* Spartacus color overrides */
  --cx-color-dark: rgb(33, 39, 56);
  --cx-color-primary-accent: rgb(0, 0, 0);

  /* Jaga colors */
  --jaga-red-100: #c38383;
  --jaga-red-300: #a42417;
  --jaga-red-700: #861f13;

  --jaga-color-accent: var(--jaga-red-300);
  --jaga-color-required: var(--jaga-red-300);
  --jaga-color-dark-red: var(--jaga-red-700);
}
