@import "src/styles/variables";
@import "src/styles/mixins";

jaga-product-list {
  .cx-product-layout {
    border: none;
  }

  .cx-pagination {
    margin-top: 8px;
  }

  .cx-sorting {
    .row {
      justify-content: center;

      cx-sorting {
        .ng-select {
          .ng-select-container {
            border: solid 1px var(--cx-color-primary);
            border-radius: 0;

            &.ng-has-value {
              border-radius: 0;
              border-color: var(--cx-color-primary);
            }
          }

          .ng-dropdown-panel {
            border-color: var(--cx-color-primary);
            border-radius: 0;

            & > .ng-dropdown-panel-items .ng-option {
              text-transform: uppercase;
              text-align: center;
              padding: 0.75rem 0.625rem;
            }
          }
        }

        .ng-value-container {
          justify-content: center;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .view-mode-selector-container {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }

  .cx-page {
    padding-bottom: 0;
  }
}
