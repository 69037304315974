@import '../../../../variables';
@import '../../../../mixins';

jaga-tab-paragraph-container {
  @extend %cx-tab-paragraph-container;

  margin: auto;
  grid: none;
  display: block;

  @include media-breakpoint-down(xl) {
    max-width: $jaga-max-width-content;
  }

  > div {
    display: flex;
    flex-direction: column;

    > button {
      @include button-reset();
      display: flex;
      justify-content: space-between;
      background: var(--cx-color-light);
      color: var(--cx-color-primary);
      font-weight: var(--cx-font-weight-bold);
      padding: 0.75rem 1rem;
      font-size: 1.25rem;
      text-transform: uppercase;
      margin-bottom: .5rem;
      text-align: start;
      order: initial;

      // after plus - minus
      &::after {
        @include jaga-fa;
      }

      &:not(active)::after {
        content: $jaga-fa-plus;
      }

      &.active::after {
        content: $jaga-fa-minus;
      }

      &:hover {
        text-decoration: none;
        @include cx-darken(88);
      }

      &:focus {
        z-index: 1;
      }
    }
    > div {
      order: initial;
      grid-column: initial;
      padding: 1rem;

      &:not(.active) {
        display: none;
      }
    }
  }
}
