@import 'src/styles/variables';

.ContentPage1Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-content;
  padding: 0;
  margin: 0 auto;


  cx-page-slot {
    flex-basis: 100%;
    margin: 0 auto;

    &.Section1 {
      &.has-components {
        padding: 1rem;
      }
    }

    &.Section2 {
      &.has-components {
        text-align: center;
        padding-top: 3rem;
      }

      h2 {
        text-align: center;
      }
    }

    &.Section6A,
    &.Section6B,
    &.Section6C {
      flex-direction: column;
      flex-wrap: nowrap;

      jaga-paragraph {
        text-align: center;
        padding: 2rem 1rem 1rem;
        max-width: $jaga-paragraph-max-width-third;
        margin: 0 auto;
      }

      jaga-image {
        flex: 0;
      }
    }


    &.Section8 {
      flex-direction: column;
      flex-wrap: nowrap;

      jaga-paragraph {
        width: 100%;
        max-width: $jaga-paragraph-max-width-content;
        text-align: center;
        padding: 2rem 1rem 1rem;
        margin: 0 auto;

        p {
          max-width: $jaga-paragraph-max-width-two-thirds;
          margin: 0 auto;
        }
      }
    }

    &.Section9A, &.Section9B,
    &.Section12A, &.Section12B, &.Section12C {
      &.has-components {
        padding: 2rem 1rem;
      }

      jaga-image {
        cx-media {
          height: 100%;
          max-height: 300px;
          text-align: center;
        }
      }

    }
  }

  cx-media {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    cx-page-slot {
      &.Section3A, &.Section3B,
      &.Section9A, &.Section9B {
        flex-basis: $jaga-flex-basis-half;
      }

      &.Section4A,
      &.Section5B,
      &.Section10A,
      &.Section11B {
        flex-basis: $jaga-flex-basis-two-thirds;
      }

      &.Section4B,
      &.Section5A,
      &.Section6A, &.Section6B, &.Section6C,
      &.Section10B,
      &.Section11A,
      &.Section12A, &.Section12B, &.Section12C {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section7A, &.Section7B, &.Section7C, &.Section7D,
      &.Section13A, &.Section13B, &.Section13C, &.Section13D {
        flex-basis: $jaga-flex-basis-quarter;
      }
    }
  }
}
