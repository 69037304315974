cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
  padding: 2rem 1.5rem 0 1.5rem;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;

  @include media-breakpoint-down(md) {
    display: inline-block;
  }

  cx-page-slot {
    &.TopContent {
      flex: 0 100%;
    }

    &.BodyContent {
      flex: 0 68%;
    }

    &.SideContent {
      flex: 0 0 30%;
      flex-direction: column;
      justify-content: flex-start;

      cx-checkout-order-summary {
        flex: unset;
      }

      cx-place-order {
        flex: unset;
        padding: 1rem 2.5rem 0;
      }

      @include media-breakpoint-down(md) {
        --cx-flex-basis: 50%;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        cx-checkout-order-summary {
          max-width: 50%;
          padding-top: 1rem;

          cx-order-summary {
            padding: 1rem 2.5rem 0;
          }
        }
        cx-place-order {
          max-width: 50%;
          padding-bottom: 2rem;
          padding-top: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        --cx-flex-basis: 100%;
        align-items: normal;

        cx-checkout-order-summary {
          max-width: 100%;
        }
        cx-place-order {
          max-width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 2rem 1rem 0;
  }

  cx-page-slot {
    &.TopContent {
      padding: 0 1rem;

      @include media-breakpoint-up(sm) {
        padding: 0;
      }
    }

    &.BodyContent {
      flex: 0 70%;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;

      > * {
        flex: 0;
      }

      .h5 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(lg) {
          margin-top: 2rem;
        }
      }

      cx-checkout-progress-mobile-top {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &.SideContent {
      flex-wrap: nowrap;

      > * {
        flex: 0;
      }

      jaga-checkout-order-summary {
        jaga-order-summary {
          padding: 1rem;

          @include media-breakpoint-up(lg) {
            padding: 0 1rem;
          }

          h4 {
            padding: 0.5rem 1rem;
            background: var(--cx-color-light);
            font-weight: var(--cx-font-weight-semi);
          }

          .cx-summary-partials {
            background: var(--cx-color-light);
            padding: 0.5rem 1rem;
          }
        }
      }

      jaga-place-order {
        flex: unset;
        padding: 0 1rem;

        @include media-breakpoint-down(md) {
          max-width: 50%;
          padding-bottom: 2rem;
          padding-top: 0;
        }

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }

    &.BottomContent {
      padding: 1rem 1rem 0 1rem;
    }
  }
}
