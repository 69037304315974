@import 'src/styles/variables';

jaga-product-attributes-tab {
  @extend %cx-product-attributes;

  .container {
    padding: 0;

    h2 {
      display: none;
    }

    table {
      margin-bottom: 1.5rem;

      th {
        h3 {
          @include header-3;
          font-weight: $jaga-font-weight-bold;
          text-transform: uppercase;
        }
      }

      tr {
        border: 0;
        white-space: nowrap;

        td {
          padding: 0.5rem;
          width: 35%;

          &:first-of-type {
            font-weight: $jaga-font-weight-bold;
            text-transform: uppercase;
            width: 65%;
          }

          ul {
            margin: 0;
          }

          @include media-breakpoint-up(md) {
            width: 75%;

            &:first-of-type {
              width: 25%;
            }
          }
        }
      }
    }
  }
}
