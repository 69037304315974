@import 'src/styles/variables';
@import "src/styles/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";


.quick-order-list-header {
  margin: 0;
  padding: 1.125rem 0;
  text-transform: uppercase;
  color: var(--cx-color-secondary);
  border-width: 0 0 0 0;
  background-color: var(--cx-color-light);
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  border-color: var(--cx-color-light);
}

.quick-order-list-desc {
  text-align: left;
  padding: 0;
}

.quick-order-list-price {
  text-align: left;
  padding: 0;

  @include media-breakpoint-down(md) {
    text-align: left;
  }

  @include media-breakpoint-only(xl) {
    padding: 0 45px 0 0;
  }
}

.quick-order-list-qty {
  text-align: center;

  @include media-breakpoint-down(md) {
    text-align: left;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 0 0 20px;
  }

  @include media-breakpoint-only(lg) {
    padding: 0 24px 0 0;
  }
}

.quick-order-list-total {
  text-align: right;
  padding: 0;
}

