@import "src/styles/variables";

.AccountPageTemplate {
  cx-order-overview,
  cx-order-details-reorder {
    display: none;
  }

  jaga-order-detail-shipping {
    background-color: var(--cx-color-background);
    order: 3;

    .cx-account-summary {
      cx-card {
        .cx-card-title {
          font-weight: $jaga-font-weight-bold;
        }
      }
    }
  }

  jaga-order-detail-totals {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
