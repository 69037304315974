@import "../../../../variables";

$mobile-footer-border: #ccc;

footer {
  background-color: var(--cx-color-light);
  margin-top: 1.5rem;

  h5 {
    text-transform: uppercase;
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.22222222);
    margin-bottom: 20px;
  }

  cx-page-layout {
    display: flex;
    flex-wrap: wrap;

    &.bottom {
      padding: 1.5rem 1rem 0;
      background: none;
      color: var(--cx-color-text);
    }

    .FooterLogo,
    .FooterAddressInfo,
    .FooterSiteContext,
    .FooterLinks {
      text-align: end;
      justify-content: flex-end;
    }

    .FooterLogo {
      max-width: 25%;
    }

    .FooterAddressInfo {
      max-width: 75%;

      p {
        margin: 0;
      }
    }

    .FooterSiteContext {
      justify-content: center;
      padding: 1rem 0;

      jaga-link,
      cx-site-context-selector {
        flex: 0;
      }
    }

    .FooterSocialMedia {
      justify-content: space-evenly;

      cx-banner,
      jaga-banner {
        max-width: 48px;
      }
    }

    .FooterCopyright,
    .FooterLinks {
      text-align: center;
    }

    .FooterLinks {
      jaga-link,
      cx-anonymous-consent-open-dialog {
        margin: 0 0.25rem 1rem;
      }

      cx-anonymous-consent-open-dialog {
        justify-content: center;

        button {
          font-weight: var(--cx-font-weight-normal);
          line-height: 1.34;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &.bottom {
        display: grid;
        max-width: $jaga-max-width-content;
        margin: 0 auto;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(6, auto);
      }

      .Footer,
      .FooterContactInfo,
      .FooterAdditionalInfo {
        grid-row: 1 / span 4;
      }

      .FooterContactInfo,
      .FooterAdditionalInfo {
        padding: 0 0.5rem;
      }

      .FooterLogo,
      .FooterAddressInfo,
      .FooterSiteContext,
      .FooterLinks {
        grid-column: 4;
      }

      .FooterLogo {
        grid-row: 1;
        max-width: 100%;

        cx-banner,
        jaga-banner {
          max-width: 50%;
        }
      }

      .FooterAddressInfo {
        grid-row: 2;
        max-width: 100%;
      }

      .FooterSiteContext {
        grid-row: 3;
        justify-content: flex-end;
      }

      .FooterSocialMedia {
        justify-content: flex-end;
        grid-row: 4;
        grid-column: 4;

        cx-banner,
        jaga-banner {
          max-width: 25px;
          margin-left: 1rem;
        }
      }

      .FooterLineSeparator {
        grid-row: 5;
        grid-column: 1 / span 4;
      }

      .FooterCopyright {
        grid-column: 1;
        text-align: start;
      }

      .FooterLinks {
        grid-column: 2 / span 3;
        text-align: start;

        jaga-link,
        cx-anonymous-consent-open-dialog {
          flex: none;
          justify-content: start;
        }
      }
    }
  }

  cx-footer-navigation {
    --cx-color-background-dark: transparent;

    cx-navigation-ui {
      justify-content: start;

      nav > ul > li {
        margin: 0;

        .wrapper {
          display: block;
          padding-bottom: 1rem;
        }

        &.is-open,
        &.is-opened {
          h5 {
            &::after {
              content: "";
            }
          }

          .wrapper {
            display: block;
          }
        }
      }
    }

    a {
      font-size: 1em;
    }
  }
}
