@import 'src/styles/variables';

jaga-product-variant-generic-selector {
  .variant-generic-selector {
    display: flex;
    flex-direction: column;

    .image-variant-container {
      display: flex;

      div {
        margin-right: 1rem;
        max-width: 80px;
        text-align: center;

        cx-media {
          display: inline-block;
          border-radius: 50%;
          min-width: 60px;
          overflow: hidden;
          border: 3px solid $jaga-color-border-grey;
          cursor: pointer;

          &.active {
            border-color: $jaga-color-border-accent;
          }

          img {
            height: 70px;
            width: 70px;
            object-fit: cover;
          }
        }
      }
    }

    .select-variant-container {
      margin: 0 0 10px 0;
    }
  }
}
