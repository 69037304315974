@import 'src/styles/variables';
@import 'src/styles/mixins';

body {
  font-family: $font-family-sans-serif;
  font-size: $jaga-base-font-size;
  line-height: $jaga-base-line-height;
}

b, strong {
  font-weight: $jaga-font-weight-bold;
}

p {
  margin-top: 0;
  margin-bottom: .5rem;
}

/* Headings */

h1,
.h1 {
  @include header-1;
}

h2,
.h2 {
  @include header-2;
}

.h3 {
  @include header-3;
}

@mixin header-4 {
  font-size: $jaga-h4-font-size;
  font-weight: $jaga-h4-font-weight;
  line-height: $jaga-h4-line-height;
  @include header;
}

.h4 {
  @include header-4;
}

.h5 {
  @include header-5;
}
