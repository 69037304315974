jaga-address-card {
  @extend %cx-card;
  .cx-card-body{
    flex-direction: row;
  }
  .column-right {
    width: 75%;
    margin:auto;
  }
  .column-left {
    width: 25%;
    margin: auto;
    font-size: 45px;
  }
}
