@import 'src/styles/variables';

.LandingPage3Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-wide;
  margin: auto;

  cx-page-slot {
    flex-basis: 100%;

    &.Section6A,&.Section6B,&.Section6C {
      flex-direction: column;
      flex-wrap: nowrap;

      jaga-paragraph {
        padding: 2rem 1rem 1rem;
        text-align: center;
      }
    }

    &.Section10 {
      &.has-components {
        padding: 1rem;
      }

      jaga-paragraph {
        max-width: 750px;
        text-align: center;
      }
    }

    &.Section11 {
      margin: auto;
      max-width: $jaga-max-width-content;

      jaga-paragraph {
        padding: 0 1rem;
      }
    }

    &.Section13A,&.Section13B {
      display: flex;
      flex-direction: row;
      justify-content: center;

      &.has-components {
        margin: 2rem 0;
      }

      cx-media {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
    }
    &.Section14A,&.Section14B,&.Section14C {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: flex-end;

      &.has-components {
        margin: 2rem 0;
      }

      cx-media {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .LandingPage3Template {
    cx-page-slot {
      &.Section3A,&.Section3B {
        flex-basis: $jaga-flex-basis-half;
      }

      &.Section4A {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section4B {
        flex-basis: $jaga-flex-basis-two-thirds;
      }

      &.Section5A {
        flex-basis: $jaga-flex-basis-two-thirds;
      }

      &.Section5B {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section6A,&.Section6B,&.Section6C {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section8A,&.Section8B,&.Section8C {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section11 {
        padding: 0;
      }

      &.Section13A,&.Section13B {
        flex-basis: $jaga-flex-basis-half;
      }
      &.Section14A,&.Section14B,&.Section14C {
        flex-basis: $jaga-flex-basis-third;
      }
    }
  }
}
