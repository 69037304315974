@import 'src/styles/variables';

jaga-email-subscribe {
  background: $jaga-color-black;
  padding: 2rem 0;

  .jaga-email-subscribe {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: $jaga-max-width-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;

    .title {
      font-size: 2rem;
      text-align: center;
      line-height: 1.125;
      font-weight: $jaga-font-weight-light;
      color: $jaga-color-white;
      margin-bottom: 1rem;
    }

    .button-wrapper {
      flex: 1;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;

      .title {
        text-align: start;
        margin-bottom: 0;
      }

      .button-wrapper {
        max-width: 500px;
        padding-left: 3rem;
      }
    }

    @include media-breakpoint-up(lg) {
      .title {
        font-size: 2.5rem;
      }
    }
  }
}
