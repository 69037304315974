@import "src/styles/variables";

jaga-cart-coupon {
  @extend %cx-cart-coupon !optional;

  .btn {
    &.btn-primary {
      font-weight: var(--cx-font-weight-bold);
    }
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .apply-coupon-button {
    min-width: fit-content;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: $jaga-font-weight-bold;
  }

  & > cx-applied-coupons > .row > .cx-coupon-card-grid {
    padding-inline-end: $bootstrap-padding;
    margin-bottom: 1rem;
  }
}
