@import "../../../variables";

cx-item-counter {
  @extend %cx-item-counter !optional;

  &:not(.readonly) {
    border-radius: 0;

    &,
    input {
      border-color: var(--cx-color-primary);
    }
  }
}
