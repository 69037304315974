@import 'src/styles/variables';
@import 'src/styles/mixins';

jaga-review-submit {
  padding: 0 1rem;

  cx-promotions {
    .cx-promotions {
      ul {
        margin: 0;
      }
    }
  }

  .cx-review-summary-edit-step {
    padding-left: 20px;

    a {
      color: #888;
      font-size: .9em;
      text-decoration: underline;
    }
  }

  .cx-review-summary-card {
    .cx-card-body {
      padding-bottom: 0;

      .cx-card-title {
        font-size: .9em;
        font-weight: var(--cx-font-weight-bold);
        text-transform: uppercase;
        color: var(--cx-color-secondary)
      }
    }
  }

  .toggleDetails {
    border: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: var(--cx-color-light);
    color: var(--cx-color-secondary);
    font-weight: var(--cx-font-weight-bold);
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    margin-bottom: .5rem;

    // after plus - minus
    &::after {
      @include jaga-fa;
    }

    &:not(active)::after {
      content: $jaga-fa-angle-down;
    }

    &.active::after {
      content: $jaga-fa-angle-up;
    }

    &:hover {
      text-decoration: none;
      @include cx-darken(88);
    }
  }

  jaga-cart-item-list {
    .cx-item-list-header {
      background: var(--cx-color-light);
    }

    .cx-item-list-header {
      padding: 0.5rem 0;
    }

    .cx-item-list-row {
      padding: 0.5rem 1rem;
    }
  }
}
