jaga-order-history {
  @extend %cx-order-history !optional;

  .cx-order-history-sort.bottom {
    justify-content: flex-start;
  }

  .cx-order-history-header {
    padding: 0;
  }

  .cx-order-history-no-order {
    --cx-font-size: 0.875rem;
  }

  .cx-order-history-table {
    td {
      @include media-breakpoint-down(sm) {
        padding: 0.2rem 1.25rem;
      }
    }
  }
}
