@import "src/styles/variables";

jaga-cart-item-list {
  @extend %cx-cart-item-list;

  .cx-item-list-header {
    @include type("8");
    background-color: var(--cx-color-light);
    margin: 0;
    padding: 1.125rem 0;
    text-transform: uppercase;
    color: var(--cx-color-secondary);
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--cx-color-light);

    .cx-item-list-desc {
      padding-left: $bootstrap-padding;
      padding-right: $bootstrap-padding;
    }
  }

  .cx-item-list-row {
    border: none;
    padding: 1.25rem 0;
  }
}
