$caretSize: 7px;
.website-selector {
  margin-right: 20px;
  @include media-breakpoint-down(md) {
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
    &:not(:first-child) {
      padding-left: 16px;
      border-left: 1px solid var(--cx-color-secondary);
    }
  }

  label {
    position: relative;
    display: flex;
    margin: 0;

    span {
      @include sr-only();
    }
  }

  cx-icon {
    position: absolute;
    right: 0;
    margin: 0;
    z-index: 0;
  }


  select {
    color: inherit;
    background-color: var(--cx-color-light);
    border: 0;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding-right: $caretSize * 2;

    outline: none;

    position: relative;
  }
}
