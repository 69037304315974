@import "src/styles/variables";

.btn {
  --cx-color-light: #efefef;

  line-height: 1.5;

  &.btn-register {
    border-width: 1px;
    font-weight: var(--cx-font-weight-normal);
  }

  &.btn-primary {
    font-size: 18px;
    font-weight: var(--cx-font-weight-normal);
    padding-inline: 12px;

    &:hover {
      background-color: var(--cx-color-secondary);
      border-color: var(--cx-color-secondary);
      color: var(--cx-color-inverse);
      filter: none;
    }

    &.active {
      background-color: var(--cx-color-secondary);
      color: var(--cx-color-inverse);
      @include cx-darken(88);
    }

    &.disabled,
    &:disabled {
      &:hover {
        background-color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
        color: var(--cx-color-inverse);
        @include cx-darken(100);
      }
    }

    &:focus {
      background-color: var(--cx-color-primary);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-inverse);

      box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: var(--cx-color-secondary);
      border-color: var(--cx-color-secondary);
      color: var(--cx-color-inverse);
      @include cx-darken(88);

      &:focus {
        box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
      }
    }
  }

  &.btn-secondary {
    background-color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    border-width: 1px;
    color: var(--cx-color-primary);
    font-size: 18px;
    font-weight: var(--cx-font-weight-normal);
    line-height: 1;
    padding-inline: 12px;

    &:hover {
      background-color: var(--cx-color-light);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-primary);

      filter: none;
    }

    &.active {
      background-color: var(--cx-color-light);
      border-color: var(--cx-color-light);
      color: var(--cx-color-primary);

      @include cx-darken(88);
    }

    &.disabled,
    &:disabled {
      background-color: var(--cx-color-light);
      border-color: var(--cx-color-light);
      color: var(--cx-color-primary);

      &:hover {
        border-color: var(--cx-color-light);
        @include cx-darken(100);
      }
    }

    &:focus {
      //this here below will not work unless cx global colors are initially declared in rbg
      background-color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-primary);

      box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: var(--cx-color-light);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-primary);
      @include cx-darken(84);

      &:focus {
        //this here below will not work unles cx global colors are initially declared in rbg
        box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
      }
    }
  }

  &.btn-link {
    font-size: 1rem;

    &:hover {
      color: var(--cx-color-primary);
    }
  }

  &.btn-cta {
    --jaga-cta-border-radius: 8px;

    align-items: center;
    background-color: var(--cx-color-transparent);
    border: 2px var(--jaga-color-accent) solid;
    border-radius: var(--jaga-cta-border-radius);
    color: var(--jaga-color-accent);
    display: flex;
    justify-content: center;
    font-weight: var(--cx-font-weight-bold);
    font-size: 0.875rem;
    height: auto;

    max-height: none;

    &:hover {
      background-color: var(--jaga-color-accent);
      border-color: var(--jaga-color-accent);
      color: var(--cx-color-inverse);
      filter: none;
    }

    &.active {
      background-color: var(--cx-color-transparent);
      color: var(--jaga-color-accent);
      @include cx-darken(88);
    }

    &.disabled,
    &:disabled {
      &:hover {
        background-color: var(--cx-color-transparent);
        border-color: var(--jaga-color-accent);
        color: var(--jaga-color-accent);
        @include cx-darken(100);
      }
    }

    &:focus {
      background-color: var(--cx-color-transparent);
      border-color: var(--jaga-color-accent);
      color: var(--jaga-color-accent);

      box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: var(--cx-color-transparent);
      border-color: var(--cx-color-accent);
      color: var(--jaga-color-accent);
      @include cx-darken(88);

      &:focus {
        box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
      }
    }
  }

  &.btn-cta-solid {
    background-color: var(--jaga-color-accent);
    color: var(--cx-color-inverse);

    &:hover,
    &:focus {
      background-color: var(--jaga-color-dark-red);
      border-color: var(--jaga-color-dark-red);
      color: var(--cx-color-inverse);
    }

    &:focus {
      box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
    }
  }

  &.btn-action {
    color: var(--cx-color-primary);
    border: 1px solid var(--cx-color-primary);
    font-weight: var(--cx-font-weight-normal);

    &:focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
    }

    &:hover {
      color: var(--cx-color-primary);
      border-width: 1px;
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-light);
    }

    &.active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: var(--cx-color-primary);
      border-width: 1px;
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-light);
      @include cx-darken(88);
    }

    &.disabled {
      color: var(--cx-color-light);
      border-width: 1px;
      border-color: var(--cx-color-light);
      opacity: 1;
    }
  }
}

button {
  &.cx-action-link {
    --cx-font-size: 14px;

    color: var(--cx-color-text);
    font-weight: var(--cx-font-weight-normal);
  }
}
