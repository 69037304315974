@import "../../../../mixins";

.HeaderLinks {
  @include media-breakpoint-up(md) {
    jaga-acc-nav {
      cursor: pointer;
      position: relative;

      nav > .wrapper {
        display: none;
        position: absolute;
        background: #fff;
        border: 1px solid #ccc;
        padding: 15px 20px;
        white-space: nowrap;
        z-index: 20;
      }

      nav:hover {
        .wrapper {
          display: initial;
        }
      }

      .is-open {
        > nav {
          > .wrapper {
            display: initial;
          }
        }
      }
    }
  }

  jaga-acc-nav-ui {
    &.flyout {
      cx-generic-link {
        display: flex;
        align-items: center;

        a {
          padding: 5px 0;
          text-decoration: none;
        }
      }

      @include media-breakpoint-up(lg) {
        &:focus-within {
          > nav {
            &:focus,
            &.is-open:focus-within {
              > .wrapper {
                display: initial;
              }
            }
          }
        }

        > nav {
          cursor: pointer;
          // top level headings
          > h5 {
            font-size: 0.9em;
            margin-top: 0.5rem;
            padding: 0;
            font-weight: normal;

            cx-icon::before {
              font-size: 1em;
            }
          }

          > span {
            padding: 0;
            margin-top: 0.35rem;
          }
        }
      }

      @include media-breakpoint-up(md) {
        > nav > span {
          display: flex;
          align-items: center;
          white-space: nowrap;

          cx-icon {
            padding-inline-start: 7px;
          }
        }

        .wrapper {
          -webkit-margin-start: -10px;
          margin-inline-start: -10px;
        }

        h5 {
          font-weight: normal;
          text-transform: none;
          font-size: 0.9em;
          margin-top: 0.5rem;
          padding: 0;
          border: 0;
          display: block;

          cx-icon::before {
            font-size: 1em;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .wrapper,
        span {
          display: none;
        }

        &.is-open {
          > nav > .wrapper {
            display: initial;
            height: auto;
            width: min-content;
            padding: 20px;
            position: absolute;
            top: 80px;
            left: 0;
            z-index: 1;
            background: var(--cx-color-background);

            .account-details > div > span {
              display: initial;
            }

            .childs {
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-top: 20px;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        &.is-open {
          > nav {
            > .wrapper {
              top: 67px;
            }
          }
        }
      }
    }
  }
}

.NavigationBar {
  --navigation-padding: 20px 20px 20px 0;

  @include media-breakpoint-up(md) {
    cx-navigation-ui {
      > nav {
        > cx-generic-link {
          > a {
            padding: var(--navigation-padding);
          }
        }

        > h5 {
          font-size: 1em;
          line-height: 20px;
          margin: 0;
          padding: var(--navigation-padding);
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  cx-navigation-ui {
    background-color: transparent;
  }
}

.SiteLinks {
  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  .jaga-help {
    &::before {
      content: $jaga-fa-question;
      font-size: 14px;
      width: 35px;
      height: 35px;
      margin: 0 auto 0.35em auto;
      padding: 7px 11px;
      border: 1px currentColor solid;
      border-radius: 100%;

      @include jaga-fa;
    }
  }
}

cx-navigation-ui {
  a {
    &:hover {
      color: currentColor;
    }
  }

  .wrapper {
    .childs > nav {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
