@import 'src/styles/variables';

.ContentPage5Template {
  display: flex;
  flex-wrap: wrap;
  max-width: $jaga-max-width-content;
  margin: 0 auto;

  cx-page-slot {
    flex-basis: 100%;
    margin: 0 auto;

    &.Section9 {
      padding: 0 1rem;
    }
  }

  @include media-breakpoint-up(md) {
    cx-page-slot {
      &.Section4A, &.Section4B, &.Section4C,
      &.Section5A, &.Section5B, &.Section5C,
      &.Section6A, &.Section6B, &.Section6C {
        flex-basis: $jaga-flex-basis-third;
      }

      &.Section7A, &.Section7B, &.Section7C, &.Section7D {
        flex-basis: $jaga-flex-basis-quarter;
      }

      &.Section8A, &.Section8B {
        flex-basis: $jaga-flex-basis-half;
      }

      &.Section9 {
        padding: 0;
      }
    }
  }
}
