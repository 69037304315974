@import '../variables';

a {
  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}
