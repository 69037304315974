@import "src/styles/variables";

.OrderConfirmationPageTemplate {
  max-width: $jaga-max-width-content;
  margin: 0 auto;

  cx-order-confirmation-shipping {
    display: none;
  }

  cx-page-slot {
    &.BodyContent {
      padding: 0 1rem;

      cx-order-confirmation-thank-you-message {
        .cx-page-header {
          background-color: white;
        }

        h2 {
          border-bottom: none;
          font-size: 1.125em;
        }

        h2::after,
        .h2::after {
          display: none;
        }

        p {
          display: none;
        }
      }

      cx-order-confirmation-items {
        .cx-order-items-header {
          display: none;
        }

        cx-cart-item-list {
          .cx-item-list-header {
            background-color: var(--cx-color-light);
          }
        }
      }

      jaga-order-confirmation-totals {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      jaga-order-confirmation-overview {
        .cx-order-review-summary {
          .cx-card-title {
            font-weight: $jaga-font-weight-bold;
          }
        }
      }
    }
  }
}
