@import '../../../../../variables';

jaga-product-downloads-tab {
  > div {
    > * {
      flex-basis: $jaga-flex-basis-half;

      @include media-breakpoint-up(md) {
        flex-basis: $jaga-flex-basis-fifth;
      }
    }
  }

  a {
    display: block;
    text-align: center;
    font-size: .9em;
    text-transform: uppercase;
    font-weight: $jaga-font-weight-bold;
    color: var(--cx-color-secondary);
    padding: 0 1rem;
    margin-bottom: 2rem;
    max-width: 14.3rem;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    cx-media {
      margin: 0 auto 0.625rem;;

      &.is-missing {
        height: initial;
        width: initial;
        min-height: 150px;
      }

      &:not(.is-missing) {
        img {
          max-height: 190px;
        }
      }
    }
  }
}
