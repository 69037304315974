@import 'src/styles/variables';


.SearchResultsEmptyPageTemplate {
  max-width: $jaga-max-width-content;
  padding: 2rem 1.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}
