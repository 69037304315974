@import '../../../../variables';

jaga-product-carousel {
  @extend %cx-product-carousel;

  .item {
    display: flex;
    flex-direction: column;

    a, a:hover {
      text-decoration: none;

      h4 {
        font-size: 1.125rem;
        font-weight: $jaga-font-weight-bold;
        min-height: auto;
      }

      .price {
        font-weight: $jaga-font-weight-bold;
      }
    }

    a {
      justify-content: flex-start;

      & > * {
        flex: 0;
      }
    }

    cx-item-counter {
      &:not(.readonly) {
        input {
          @include media-breakpoint-up(sm) {
            max-width: 48px;
          }
        }
      }
    }
  }

  cx-media {
    &.is-missing {
      min-height: 155px;
    }
  }
}
