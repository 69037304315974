@import "../variables";
@import "../mixins";

$form-control-disabled-color: #f5f5f5;

label {
  .label-content {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8em;
    margin-bottom: 0;
  }
}

.select-wrapper {
  position: relative;

  cx-icon {
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 0;
  }

  select {
    appearance: none;
    z-index: 1;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding: 0.5rem 0;
  font-size: 1em;
  height: auto;

  &:disabled,
  &[readonly] {
    background-color: $form-control-disabled-color;
    padding-inline-start: 0.5rem;
  }

  &::placeholder {
    color: var(--cx-color-secondary);
  }

  &.ng-touched.ng-invalid::placeholder {
    color: var(--cx-color-danger);
  }

  &[type="text"]:focus,
  &:focus {
    background: initial;
  }

  /* Hide controls in number input */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.required {
  &::after {
    content: "*";
    color: var(--jaga-color-required);
    font-size: 1rem;
  }
}

cx-form-errors {
  p {
    padding: 0 0 0 1.875rem;
  }
}
