%jaga-searchbox__body {
  // when there are results and the searchbox is active
  // we display the searchbox
  &.searchbox-is-active {
    &.has-searchbox-results {
      cx-searchbox {
        .results {
          display: block;
        }
      }

      // the searchbox requires an overlay over the page (except for the header)
      // we do this with an overlay under the header, on top of the rest of the page
      header {
        position: relative;
        z-index: 100;
      }

      cx-storefront {
        &:before {
          content: "";
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;

          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &:not(.searchbox-is-active) {
    @include media-breakpoint-down(sm) {
      // hide the input on mobile when there's no interaction with searchbox
      cx-searchbox {
        input {
          // we cannot use display:none, visible:hidden or opacity: 0
          // as this will no longer emit a focus event to the controller logic
          width: 0;
          padding: 0;
        }

        cx-icon.reset {
          display: none;
        }
      }
    }
  }
}

cx-searchbox {
  @extend %cx-searchbox;

  div {
    background: var(--transparant);
  }

  @include media-breakpoint-up(md) {
    border: 1px solid currentColor;
    border-radius: 25px;
    z-index: 1;

    &:focus-within {
      transition: none;
      border-width: 2px;
      margin-top: -1px;
    }

    .results {
      margin-top: 2px;
    }
  }

  label {
    background: none;
    padding: 0 0 0 1rem;

    @include media-breakpoint-down(sm) {
      --cx-font-size: 14px;

      .search {
        padding: 0;
      }

      input {
        background-color: var(--cx-color-light);
      }

      cx-icon {
        &.reset {
          position: absolute;
          top: 70px;
          right: 20px;
          left: initial;
        }
      }
    }

    @include media-breakpoint-up(md) {
      border: none;
    }

    input {
      &::placeholder {
        font-size: 0.9em;
      }

      @include media-breakpoint-up(lg) {
        & {
          border: 0;

          &:focus {
            outline: 0;
          }
        }
      }
    }

    div.search-icon {
      --cx-color-medium: black;
    }
  }

  @include media-breakpoint-down(sm) {
    cx-icon.fa-search {
      &::before {
        display: block;
        width: 35px;
        height: 35px;
        padding: 9px 7px;
        border: 1px currentColor solid;
        border-radius: 100%;
      }
    }
  }
}
