@import "src/styles/variables";

cx-category-navigation {
  --nav-height: 62px;

  max-width: $jaga-max-width-content;
  margin: 0 auto;

  cx-navigation-ui.flyout > nav > ul > li:hover > button + .wrapper {
    display: initial;
  }

  cx-navigation-ui {
    @include media-breakpoint-up(lg) {
      height: var(--nav-height);
      padding: 0 0.5rem;
    }

    &.flyout {
      > nav > ul {
        > li {
          > .wrapper {
            border-width: 0 0 1px 0;
            margin: 0;
            top: var(--nav-height);

            @include media-breakpoint-up(lg) {
              padding: 0.5rem 0;
            }
          }

          &:hover {
            .wrapper {
              display: initial;
            }
          }
        }
      }
    }

    li {
      @include media-breakpoint-down(md) {
        &,
        &:last-of-type {
          border-top: 1px solid $jaga-color-border-grey;
        }
      }

      h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1em;
        margin-bottom: 0;
      }

      .wrapper {
        left: 0;
        width: 100%;

        cx-generic-link {
          text-decoration: none;
          text-transform: uppercase;

          &.all {
            font-weight: var(--cx-font-weight-bold);
          }
        }

        @include media-breakpoint-up(lg) {
          > .all {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          > .all,
          > .childs {
            max-width: $jaga-max-width-content;
            margin: 0 auto;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
      }
    }
  }
}
